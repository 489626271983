import _typeof from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/typeof.js";
var __jsx = React.createElement;
import React, { useEffect, useState } from 'react';
import cookie from 'js-cookie';
import { useRouter } from 'next/router';
var authCookieKey = process.env.NEXT_PUBLIC_AUTH;
var profileKey = process.env.NEXT_PUBLIC_PROFILE_KEY;
var eKey = process.env.NEXT_PUBLIC_E_KEY;
export var withAuth = function withAuth(WrappedComponent) {
  var WithAuth = function WithAuth(props) {
    var publicPaths = ['/', '/ar/', '/forget-password/', '/ar/forget-password/', '/reset-password/', '/ar/reset-password/'];
    var router = useRouter();
    var pathname = router.pathname;
    var url = pathname.split('?')[0];
    var _useState = useState(false),
      auth = _useState[0],
      setAuth = _useState[1];
    // Check if the user is authenticated
    useEffect(function () {
      var authCookie = cookie.get(authCookieKey);
      var profile = cookie.get(profileKey);
      var email = cookie.get(eKey);
      var authorization = JSON.parse(_typeof(authCookie) == 'object' ? authCookie : '{}');
      // Check if the auth cookie exists
      if ((!authorization || !profile || !email) && !publicPaths.includes(pathname == '/' ? '/' : url + '/')) {
        setAuth(false);
        router.push('/');
      } else {
        if (url === '/' && authorization && profile && email) {
          router.replace('/dashboard');
          setAuth(true);
        } else setAuth(true);
      }
    }, []);
    return publicPaths.includes(pathname == '/' ? '/' : url + '/') ? __jsx(WrappedComponent, props) : auth && __jsx(WrappedComponent, props);
  };
  return WithAuth;
};