import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["children", "className", "onClick", "type", "disabled", "variant", "startIcon", "endIcon", "element", "w100", "twoButtons"];
var __jsx = React.createElement;
import classNames from "classnames";
import React from "react";
import styles from "./Button.module.scss";
var Button = function Button(props) {
  var children = props.children,
    className = props.className,
    onClick = props.onClick,
    _props$type = props.type,
    type = _props$type === void 0 ? 'button' : _props$type,
    _props$disabled = props.disabled,
    disabled = _props$disabled === void 0 ? false : _props$disabled,
    _props$variant = props.variant,
    variant = _props$variant === void 0 ? "contained" : _props$variant,
    startIcon = props.startIcon,
    endIcon = props.endIcon,
    element = props.element,
    w100 = props.w100,
    twoButtons = props.twoButtons,
    restProps = _objectWithoutProperties(props, _excluded);
  var Component = element || 'button';
  return __jsx(Component, _extends({
    className: classNames(styles.root, styles[variant], className),
    style: {
      width: w100 ? '100%' : undefined,
      display: twoButtons && 'inline-block',
      marginRight: twoButtons && '5px'
    },
    onClick: onClick,
    type: type,
    disabled: disabled
  }, restProps), startIcon && __jsx("div", {
    className: classNames(styles.startIcon)
  }, startIcon), children, endIcon && __jsx("div", {
    className: classNames(styles.endIcon)
  }, endIcon));
};
export default Button;