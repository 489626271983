import _defineProperty from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _slicedToArray from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var __jsx = React.createElement;
import classnames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';
import Loading from '../Loading';
export default function Loader(props) {
  var _classnames;
  var className = props.className,
    style = props.style,
    block = props.block,
    children = props.children,
    active = props.active,
    _props$delay = props.delay,
    delay = _props$delay === void 0 ? 0 : _props$delay,
    color = props.color;
  var _React$useState = React.useState(active),
    _React$useState2 = _slicedToArray(_React$useState, 2),
    running = _React$useState2[0],
    setRunning = _React$useState2[1];
  var inited = React.useRef();
  var timeoutRef = React.useRef();
  React.useEffect(function () {
    if (!inited.current) return;
    if (active) {
      timeoutRef.current = setTimeout(function () {
        return setRunning(true);
      }, delay);
    }
    if (!active) {
      clearTimeout(timeoutRef.current);
      setRunning(false);
    }
  }, [active]);
  React.useEffect(function () {
    inited.current = true;
  }, []);
  return __jsx("div", {
    className: classnames(styles.loader, (_classnames = {}, _defineProperty(_classnames, styles.loader_active, running), _defineProperty(_classnames, styles.loader_block, block), _classnames), className),
    style: style
  }, __jsx("div", {
    className: styles.loader__wrapper
  }, children), active && __jsx("div", {
    className: styles.loader__spinner
  }, __jsx(Loading, {
    color: color
  }), ' '));
}