var __jsx = React.createElement;
function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import React from 'react';
import { css } from '@emotion/react';
import PuffLoader from 'react-spinners/PuffLoader';
// BeatLoader
var override = process.env.NODE_ENV === "production" ? {
  name: "1fr2k2u",
  styles: "display:inline-block;margin:0 auto;width:50px;border-color:var(--primary-color)"
} : {
  name: "mhqfan-override",
  styles: "display:inline-block;margin:0 auto;width:50px;border-color:var(--primary-color);label:override;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9hcHAvc3JjL2NvbXBvbmVudHMvTG9hZGluZy9pbmRleC5qcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFLb0IiLCJmaWxlIjoiL2FwcC9zcmMvY29tcG9uZW50cy9Mb2FkaW5nL2luZGV4LmpzIiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IFJlYWN0IGZyb20gJ3JlYWN0JztcblxuaW1wb3J0IHsgY3NzIH0gZnJvbSAnQGVtb3Rpb24vcmVhY3QnO1xuaW1wb3J0IFB1ZmZMb2FkZXIgZnJvbSAncmVhY3Qtc3Bpbm5lcnMvUHVmZkxvYWRlcic7XG4vLyBCZWF0TG9hZGVyXG5jb25zdCBvdmVycmlkZSA9IGNzc2BcbiAgICBkaXNwbGF5OiBpbmxpbmUtYmxvY2s7XG4gICAgbWFyZ2luOiAwIGF1dG87XG4gICAgd2lkdGg6IDUwcHg7XG4gICAgYm9yZGVyLWNvbG9yOiB2YXIoLS1wcmltYXJ5LWNvbG9yKTtcbmA7XG5jb25zdCBpbmRleCA9ICh7IGNvbG9yIH0pID0+IHtcbiAgICByZXR1cm4gKFxuICAgICAgICA8ZGl2IHN0eWxlPXt7IHdpZHRoOiAnMTAwJScsIHRleHRBbGlnbjogJ2NlbnRlcicsIG1hcmdpbjogJ2F1dG8nIH19PlxuICAgICAgICAgICAgPFB1ZmZMb2FkZXJcbiAgICAgICAgICAgICAgICBjb2xvcj17Y29sb3IgPyBjb2xvciA6ICd2YXIoLS1wcmltYXJ5LWNvbG9yKSd9XG4gICAgICAgICAgICAgICAgbG9hZGluZz17dHJ1ZX1cbiAgICAgICAgICAgICAgICBjc3M9e292ZXJyaWRlfVxuICAgICAgICAgICAgICAgIHNpemU9ezUwfVxuICAgICAgICAgICAgLz5cbiAgICAgICAgPC9kaXY+XG4gICAgKTtcbn07XG5cbmV4cG9ydCBkZWZhdWx0IGluZGV4O1xuIl19 */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var index = function index(_ref) {
  var color = _ref.color;
  return __jsx("div", {
    style: {
      width: '100%',
      textAlign: 'center',
      margin: 'auto'
    }
  }, __jsx(PuffLoader, {
    color: color ? color : 'var(--primary-color)',
    loading: true,
    css: override,
    size: 50
  }));
};
export default index;