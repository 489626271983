var __jsx = React.createElement;
import React from 'react';
import { useRouter } from 'next/router';
import styles from './TextLink.module.scss';
import { selectDriverForContract } from '../../../pages/drivers/driversRedux/DriversIndex';
import { useDispatch } from 'react-redux';
import Link from 'next/link';
export var TextLink = function TextLink(_ref) {
  var text = _ref.text,
    to = _ref.to,
    query = _ref.query,
    disabled = _ref.disabled,
    additionalStyles = _ref.additionalStyles,
    className = _ref.className;
  var params = new URLSearchParams(query);
  var link = to + '?' + params.toString();
  return __jsx(Link, {
    href: link,
    style: additionalStyles,
    className: [styles.link, '!text-[color:var(--primary-color)]', className].join(' ')
  }, text);
};
export var AssignLink = function AssignLink(_ref2) {
  var text = _ref2.text,
    to = _ref2.to,
    query = _ref2.query,
    driver = _ref2.driver;
  var router = useRouter();
  var dispatch = useDispatch();
  var handleRedirect = function handleRedirect() {
    dispatch(selectDriverForContract(driver));
    router.replace({
      pathname: to,
      query: query
    }, undefined, {
      shallow: true,
      scroll: false
    });
  };
  return __jsx("p", {
    className: [styles.link, '!text-[color:var(--primary-color)]'].join(' '),
    onClick: handleRedirect
  }, text);
};